import { Fragment, useEffect, useRef, useState } from "react";
import axios from "axios";

import { useLocation } from "react-router-dom";
import { FaCoins, FaFileInvoice, FaPerson, FaSpinner } from "react-icons/fa6";
import { LoadingTable } from "./loadingtable";
import { FaWarehouse } from "react-icons/fa";

import {
  FaCashRegister,
  FaExclamationTriangle,
  FaSearch,
} from "react-icons/fa";
import { Dialog, Transition } from "@headlessui/react";
import {
  Chip,
  Input,
  Tab,
  Tabs,
  TabsHeader,
  Typography,
} from "@material-tailwind/react";

import { buildLink, formatNumber } from "../functions";
import ReactPaginate from "react-paginate";

export function InvoicesTable() {
  const [items, setItems] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [activeFilter, setActiveFilter] = useState("all");
  const [pageCount, setPageCount] = useState(null);
  const [page, setPage] = useState(1);
  const [pageDialog, setPageDialog] = useState(1);
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const location = useLocation();
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const handlePageClick = (event) => {
    console.log(event);
    setPage(parseInt(event.selected) + 1);
  };
  const handlePageClickDialog = (event) => {
    console.log(event);
    setPageDialog(parseInt(event.selected) + 1);
  };

  useEffect(() => {
    getItems();
  }, [location.pathname, activeFilter, page]);

  function getItems() {
    setItems(null);
    if (searchValue) {
      setPage(1);
      setLoadingSearch(true);
    }
    setLoading(true);
    axios
      .get(
        buildLink(
          "invoices",
          `&filter=${activeFilter}${
            searchValue ? `&search=${searchValue}` : ""
          }&page=${page}&per_page=50`
        )
      )
      .then((res) => {
        setItems(res.data.data);
        setPageCount(res.data.last_page);
        setLoading(false);
        setLoadingSearch(false);
      });
  }

  useEffect(() => {
    if (searchValue == "") {
      getItems();
    }
  }, [searchValue]);

  useEffect(() => {
    setLoadingDialog(true);

    axios
      .get(
        buildLink(
          "invoices/invoiceDetails",
          `&invoice_id=${invoiceDetails.invoiceID}&idWhouse=${invoiceDetails.idWhouse}&page=${pageDialog}&per_page=50`
        )
      )
      .then((res) => {
        setInvoiceDetails((prev) => ({
          ...prev,
          ...res.data,
        }));
        setLoadingDialog(false);
      });
  }, [pageDialog]);

  const openInvoiceDetails = (obj) => {
    setLoadingDialog(true);
    setInvoiceDetails(obj);
    setOpen(true);

    axios
      .get(
        buildLink(
          "invoices/invoiceDetails",
          `&invoice_id=${obj.invoiceID}&idWhouse=${obj.idWhouse}&page=${pageDialog}&per_page=50`
        )
      )
      .then((res) => {
        setInvoiceDetails((prev) => ({
          ...prev,
          ...res.data,
        }));
        setLoadingDialog(false);
      });
  };

  const handleInputChange = (search) => {
    setSearchValue(search);

    // Call debounceSearch function instead of directly calling the API
    // getItems(search);
  };

  const TABS = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Sales",
      value: "1",
    },
    {
      label: "Purshaces",
      value: "3",
    },
  ];

  const TABLE_HEAD = ["", "Inv", "Customer", "Ammount", "Date"];

  return (
    <div className=" container  w-full flex-col flex gap-2  h-full bg-white shadow-md rounded-md ">
      <div className=" p-5 flex flex-col w-full justify-start gap-5 md:gap-10">
        <div className=" flex flex-col justify-start  text-left">
          <h1 className=" font-extrabold text-xl  ">Invoice List</h1>
          <p className=" text-sm  text-gray-500">
            See all invoice and invoice Info in your data
          </p>
        </div>
        <div className=" flex flex-col md:flex-row w-full  gap-5  justify-between">
          <Tabs value="all" className="w-full  md:w-max">
            <TabsHeader>
              {TABS.map(({ label, value }) => (
                <Tab
                  onClick={() => {
                    setActiveFilter(value);
                  }}
                  key={value}
                  value={value}
                >
                  &nbsp;&nbsp;{label}&nbsp;&nbsp;
                </Tab>
              ))}
            </TabsHeader>
          </Tabs>
          <div className=" flex flex-row justify-end gap-1">
            <div className="w-full md:w-72">
              <Input
                onChange={(e) => handleInputChange(e.target.value)}
                label="Search"
              />
            </div>
            <button
              onClick={() => getItems()}
              disabled={loadingSearch}
              className=" rounded-md  bg-cyan-400 text-white px-4"
            >
              {loadingSearch ? (
                <div className=" animate-spin">
                  <FaSpinner />
                </div>
              ) : (
                <FaSearch />
              )}
            </button>
          </div>
        </div>
      </div>

      {loading ? (
        <LoadingTable />
      ) : (
        <div className="container w-full overflow-y-auto mb-8 mt-2">
          <table className="w-full min-w-max table-auto text-left">
            <thead className=" border-y border-blue-gray-100 bg-blue-gray-50/50 p-4  opacity-70 text-sm font-normal">
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                  >
                    {head}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {items &&
                items.map(
                  (
                    {
                      Ref,
                      description,
                      DocDate,
                      NetAmnt,
                      typedoc,
                      IdOp,
                      idwhouse,
                      warehouse_name,
                    },
                    index
                  ) => {
                    const isLast = index === items.length - 1;
                    const classes = isLast
                      ? "p-4"
                      : "p-4 border-b border-blue-gray-50";

                    return (
                      <tr key={Ref}>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {typedoc == 1 ? <FaCashRegister /> : <FaCoins />}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <button
                            className={`flex flex-col`}
                            onClick={() =>
                              openInvoiceDetails({
                                invoiceID: IdOp,
                                idWhouse: idwhouse,
                                warehouse_name: warehouse_name,
                                customerName: description,
                                invoiceName: Ref,
                                date_added: DocDate,
                              })
                            }
                          >
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {Ref}
                            </Typography>
                          </button>
                        </td>
                        <td className={classes}>
                          <div className="flex flex-col">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {description}
                            </Typography>
                          </div>
                        </td>
                        <td className={classes}>
                          <div className="flex flex-col">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {formatNumber(NetAmnt)}
                            </Typography>
                          </div>
                        </td>
                        {/* <td className={classes}>
        <div className="flex flex-col">
          <Typography
            variant="small"
            color="blue-gray"
            className="font-normal"
          >
            {typedoc == 1 ? <span className=' text-blue-800'>revenu</span>:<span className='text-red-500'>cost</span>}
          </Typography>
       
        </div>
      </td> */}
                        <td className={classes}>
                          <div className="flex flex-col">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className=" text-red-600 font-bold"
                            >
                              {DocDate}
                            </Typography>
                          </div>
                        </td>
                      </tr>
                    );
                  }
                )}
            </tbody>
          </table>
        </div>
      )}

      {pageCount > 1 && (
        <ReactPaginate
          pageCount={Math.ceil(pageCount / 50)}
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          previousLabel={"<"}
          nextLabel={">"}
          activeClassName={"active-pagination-category"}
          containerClassName={"pagination"}
          className={"category-pagination"}
          forcePage={Number(page) > 0 ? Number(page) - 1 : 0}
        ></ReactPaginate>
      )}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all md:w-[70%]  w-[95%]">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="flex md:flex-row flex-col">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                        <FaFileInvoice
                          className="h-6 w-6 text-[rgb(0,172,193)]"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900 border-b w-full pb-5"
                        >
                          <div className="flex flex-col gap-2">
                            <p className="text-2xl">Invoice Details</p>
                            <div className="flex flex-row  w-full gap-5 justify-center lg:justify-between ">
                              <span className="text-sm text-gray-800">
                                {invoiceDetails.invoiceName}
                              </span>

                              <span className=" text-gray-600 md:text-sm text-xs font-thin my-auto">
                                Date Added: {invoiceDetails.date_added}
                              </span>
                            </div>
                            <h2 className="text-sm text-gray-800 flex flex-row lg:justify-start justify-center gap-2">
                              <FaWarehouse className="my-auto text-gray-600" />

                              <span>{invoiceDetails.warehouse_name}</span>
                            </h2>
                            <h2 className="text-sm text-gray-800 flex flex-row lg:justify-start justify-center gap-2">
                              <FaPerson className="my-auto text-gray-600" />
                              <span>{invoiceDetails.customerName}</span>
                            </h2>
                          </div>
                        </Dialog.Title>
                        <div className="flex flex-col ">
                          <h2 className="text-2xl font-bold mt-4">
                            Invoice Items
                          </h2>
                          {loadingDialog ? (
                            <div className=" flex justify-center my-4 text-center">
                              <FaSpinner className=" animate-spin text-2xl" />
                            </div>
                          ) : (
                            <div className=" mt-4  h-[300px] overflow-y-auto ">
                              <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                  <tr>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Item
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Unit
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Price
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Quantity
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Total
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                  {invoiceDetails.data &&
                                    invoiceDetails.data.map((item) => (
                                      <tr key={item.id}>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                          {item.item_name}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                          {item.unit_name}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                          {formatNumber(item.Price)}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                          {parseInt(item.qty)}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-red-900">
                                          {formatNumber(item.Nett)}
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          )}

                          {invoiceDetails.last_page &&
                            invoiceDetails.last_page > 1 && (
                              <ReactPaginate
                                pageCount={Math.ceil(invoiceDetails.total / 50)}
                                onPageChange={handlePageClickDialog}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={1}
                                previousLabel={"<"}
                                nextLabel={">"}
                                activeClassName={"active-pagination-category"}
                                containerClassName={"pagination"}
                                className={"category-pagination"}
                                forcePage={
                                  Number(pageDialog) > 0
                                    ? Number(pageDialog) - 1
                                    : 0
                                }
                              ></ReactPaginate>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
