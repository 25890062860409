import { Routes, Route } from "react-router-loading";

import Cookies from "js-cookie";
import Home from "./pages/homePage";
import TopHeader from "./components/top-header";
import SideBar from "./components/sideBar";
import ItemsPage from "./pages/itemsPage";
import ClientSuppliersPage from "./pages/clientSupliersPage";
import "tailwindcss/tailwind.css";
import { useContext, useRef } from "react";
import LogInPage from "./pages/loginPage";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import CashesPage from "./pages/cashesPage";
import { AccountContext } from "./contexts/AccountContext";
import axios from "axios";
import { buildLink } from "./functions";
import StartPage from "./pages/startPage";
import LoadingLoginImag from "./components/loadingLoginImag";

const App = () => {
  const [accountState, accountDispatch] = useContext(AccountContext);

  const location = useLocation();
  const headerEl = document.getElementById("headercolor");
  const divRef = useRef(null);
  const handleScroll = () => {
    // alert("kljdhsfkhj")
    if (headerEl) {
      const scrollTop = divRef.current.scrollTop;

      if (scrollTop > 10) {
        headerEl.classList.add("color");
      } else {
        headerEl.classList.remove("color");
      }
    }
  };

  // useEffect(()=>{
  //   alert(accountState.loged)
  // },[accountState.loged])

  useEffect(() => {
    // alert(location.pathname =='/')
    //  if(accountState.loged){
    divRef.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    //  }
    return () => {};
  }, [location.pathname]);

  return (
    <div className=" w-full overflow-hidden">
      {location.pathname == "/" || location.pathname == "/login" ? (
        <></>
      ) : (
        <TopHeader />
      )}
      <div
        className={`  ${
          !location.pathname.includes("/login") ? "h-[90vh]" : "h-screen"
        } relative flex flex-row justify-start w-screen`}
      >
        {location.pathname == "/" || location.pathname == "/login" ? (
          <></>
        ) : (
          <SideBar />
        )}
        <div
          ref={divRef}
          onScroll={() => handleScroll()}
          className=" bg-gray-100  overflow-y-auto w-full"
        >
          {/* { (location.pathname=='/' || location.pathname.includes('/login') || accountState.loged) &&  */}

          <>
            {accountState.loadingLogin ? (
              <LoadingLoginImag />
            ) : (
              (location.pathname == "/" ||
                location.pathname.includes("/login") ||
                accountState.loged) && (
                <Routes>
                  <Route path="/" element={<StartPage />} />
                  <Route path="/home" element={<Home />} />
                  <Route path="/login" element={<LogInPage />} />
                  <Route path="/items" element={<ItemsPage />} />
                  <Route path="/suppliers" element={<ClientSuppliersPage />} />
                  <Route path="/cash" element={<CashesPage />} />

                  {/* <Route path='/rre'element={<CashesPage/>}/> */}
                </Routes>
              )
            )}
          </>
          {/* }  */}
        </div>
      </div>
    </div>
  );
};

export default App;
